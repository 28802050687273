import { Card, Col, Container, Row } from 'react-bootstrap';
const Contact = () => {
    return (
        <Container className="App-Page ">
          
          <Row id="faq"  data-aos="fade-up">
            <Col className='justify-content-center'><h1>Contact</h1></Col>
          </Row>
          <Card className='p-5'>
            <Card.Body>
                <Row className='justify-content-center'>Steven Clark</Row>
                <Row className='justify-content-center'><a href='mailto:clarks@gmail.com'>clarks@gmail.com</a></Row>
            </Card.Body>
          </Card>
        </Container>
    )
}

export default Contact
