
import "./pdf.css";
import { useState } from 'react';
import { Button, Col, Container, Image, Row, Stack, Table } from 'react-bootstrap';
import BackIcon from './images/arrow_circle_left_white_24dp.svg'
import ForwardIcon from './images/arrow_circle_right_white_24dp.svg'
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FinalDeck = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return(

              <Container >
              <Row data-aos="fade-up" id="press" >
            <Col className='justify-content-center'><h1>Final Presentation Deck</h1></Col>
          </Row>
          <Row className="text-start" data-aos="fade-up">
            <Col className="d-flex flex-column align-items-center">
            <div id='PDFContainer' className="mt-4">
            <Document className={"PDFDocument"}  file="SpaceGiant_RocketRescueFinalPresentationStevenClark.pdf" onLoadSuccess={onDocumentLoadSuccess}>
            
                <Page className={"PDFPage PDFPageOne"} pageNumber={pageNumber} renderTextLayer={false} renderInteractiveForms={false} />
                
            </Document>
            </div>
                <Stack className="w-100 mt-3 justify-content-center" direction='horizontal' gap={2}>
                <Button size="sm" variant="link" onClick={(evt) => {
                    evt.preventDefault()
                    if(pageNumber > 1) {
                        setPageNumber(pageNumber - 1)
                    }
                }}><Image fluid src={BackIcon}/></Button>
                Page {pageNumber} of {numPages}
                <Button size="sm" variant="link" onClick={(evt) => {
                    evt.preventDefault()
                    if(pageNumber < numPages) {
                        setPageNumber(pageNumber + 1)
                    }
                }}><Image fluid src={ForwardIcon}/></Button>
                </Stack>

            </Col>
            
          </Row>

              </Container>
    )
}

export default FinalDeck