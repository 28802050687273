import { Col, Container, Row, Accordion, Image, ListGroup } from 'react-bootstrap';
import Teleport from './images/teleport.png'

const FAQ = () => {
    return(
        <Container className="App-Page ">
          
          <Row id="faq"  data-aos="fade-up">
          <Col className='justify-content-center'><h1>FAQ</h1></Col>
          </Row>
          <Row data-aos="fade-up">
            <Col >
            <Accordion defaultActiveKey="0" flush className="text-start">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>How do I install the APK?</Accordion.Header>
                    <Accordion.Body>
                    <ListGroup>
                        <ListGroup.Item><a href="https://sidequestvr.com/setup-howto" target="_blank">Download the SideQuest Application</a></ListGroup.Item>
                        <ListGroup.Item>Install the application.</ListGroup.Item>
                        <ListGroup.Item>For Windows installations, install the drivers from the <a href="https://developer.oculus.com/downloads/package/oculus-adb-drivers/" target="_blank">Meta site</a>.</ListGroup.Item>
                        <ListGroup.Item>Register as a developer on the <a href="https://developer.oculus.com/manage/" target="_blank">Meta site.</a></ListGroup.Item>
                        <ListGroup.Item>On the Meta Quest phone App. You will need to turn on Developer Mode. Turn on your headset and open the phone app. Then under Devices click Developer Mode.</ListGroup.Item>
                        <ListGroup.Item>Use a USB-C cable to connect your headset to your computer.</ListGroup.Item>
                        <ListGroup.Item>On your headset click to allow USB debugging when prompted. Also, click allow access to data, when prompted.</ListGroup.Item>
                        <ListGroup.Item>On the SideQuest application click the "Install APK file from folder on your computer" icon and select the APK file for Space Giant Rocket Resuce.</ListGroup.Item>
                        <ListGroup.Item>On your headset, click the Applications icon (9 dots) and then in the top right corner select "Unknown Sources" from the drop down. Here you can launch the game.</ListGroup.Item>
                    </ListGroup>

                    <div className='d-flex justify-content-center ratio ratio-16x9 mt-3 '>
                        <iframe src="https://www.youtube.com/embed/lFTXv2aScJ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>How do I teleport?</Accordion.Header>
                    <Accordion.Body>
                    <p>Press the thumbstick forward on either controller. Doing this will cast a line showing you where you will teleport. When you release the thumbstick, you will teleport to that position.</p>
                    <Image src={Teleport} fluid/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>How do I use my wrist menu?</Accordion.Header>
                    <Accordion.Body>
                    <p>To access your wrist menu press the Y button on the left controller or the B button on the right controller. Doing this will open the wrist menu on that hand. With your other hand, point it at the menu. Press the trigger on your pointing hand to select items from the menu. You will see a line.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Where do I place my Space Giant tools?</Accordion.Header>
                    <Accordion.Body>
                        <p>Your Space Giant tools apply forces to rockets that pass in front of the tools. The simulation rockets leave a line behind, showing their path. You use this path as an indicator of where to place your tools. You will place the device near this line. Rockets can crash into the tools, so you must carefully put the tool at a reasonable distance. </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Known Bugs</Accordion.Header>
                    <Accordion.Body>
                        <p>A couple of times it has been reported that the start button does not work. This issue is being investigated.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>What are 3 professional goals for doing this project?</Accordion.Header>
                    <Accordion.Body>
                        <p>I wanted to learn Unity and specfically XR Development.</p>
                        <p>I am interested in indie game development and would like to get a game at least on the AppLab.</p>
                        <p>I would like to get into the OculusStart program after getting a game on the AppLab.</p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </Col>
          
          </Row>
          </Container>
    )
}

export default FAQ
