import logo from './images/Logo.svg';
import { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, Image, InputGroup, ListGroup, ListGroupItem, Nav, Navbar, Offcanvas, Row, Table } from 'react-bootstrap';
import {
    Link,
    useLocation
  } from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap'
const AppNav = () => {
  let location = useLocation()
  const [scrolled, setScrolled] = useState(false)
  const [useDark, setUseDark] = useState(false)
  const changeNavColor = () => {
    if(window.scrollY > 100) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }
  
  useEffect(() => {
    console.log(location)
    if(location.pathname == "/") {
      if(scrolled) {
        setUseDark(true)
      } else {
        setUseDark(false)
      }
    } else {
      setUseDark(true)
    }
  },[location, scrolled])
  useEffect(() => {
    window.scrollTo(0,0)
  }, [location])
  window.addEventListener('scroll', changeNavColor)
    return (
        <Navbar collapseOnSelect={true} fixed='top' bg="dark" expand="md" className={`navbar-dark App-Nav ${useDark ? 'App-Nav-Dark' : ''}`}>
        <Container fluid>
          <Navbar.Brand ><img src={logo} className={`App-Logo ${useDark ? 'App-Logo-Small' : ''}`} alt="Space Giant Logo" /></Navbar.Brand>
          <Navbar.Toggle onClick={evt => {
            setScrolled(true)
          }}   aria-controls="offcanvasNavbar-expand"></Navbar.Toggle>
          <Navbar.Collapse id="offcanvasNavbar-expand">
            <Nav className="justify-content-end flex-grow-1 pe-5">
              <Nav.Item>
                <LinkContainer to="/"><Nav.Link>Home</Nav.Link></LinkContainer>
                
              </Nav.Item>
              <Nav.Item>
              <LinkContainer to="/Beta"><Nav.Link>Beta Download</Nav.Link></LinkContainer>

              </Nav.Item>
              <Nav.Item>
              <LinkContainer to="/Press"><Nav.Link>Press</Nav.Link></LinkContainer>
                
              </Nav.Item>
              <Nav.Item>
              <LinkContainer to="/FAQ"><Nav.Link>FAQ</Nav.Link></LinkContainer>
              </Nav.Item>
              <Nav.Item>
              <LinkContainer to="/Contact"><Nav.Link>Contact</Nav.Link></LinkContainer>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
}

export default AppNav
