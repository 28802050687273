
import { Col, Container, Row, Table } from 'react-bootstrap';


const Press = () => {
    return(

              <Container className="App-Page">
              <Row data-aos="fade-up" id="press" >
            <Col className='justify-content-center'><h1>Press</h1></Col>
          </Row>

          <Row data-aos="fade-up">
            <h3 className='text-start'>Factsheet</h3>
          </Row>
          <Row className="text-start" data-aos="fade-up">
            <Col lg={8}>
            <Table >
            <tbody>
            <tr>
              <th>Developer</th>
              <td>Steven Clark</td>
            </tr>
            <tr>
              <th>Website</th>
              <td>SpaceGiantGame.com</td>
            </tr>
            <tr>
              <th>Release Date</th>
              <td>Coming in 2023 to AppLab and full release expected in 2024</td>
            </tr>
            <tr>
              <th>Supported Hardware</th>
              <td>Quest 2 and Quest Pro</td>
            </tr>
            <tr>
              <th>Tags</th>
              <td>VR, Adventure, Action, Puzzle, Physics-Based</td>
            </tr>
            <tr>
              <th>Languages</th>
              <td>English</td>
            </tr>
            <tr>
              <th>Players</th>
              <td>1</td>
            </tr>
            </tbody>
          </Table>
            </Col>
          </Row>
          <Row data-aos="fade-up text-start">
            <h3 className='text-start'>Description</h3>
          </Row>
          <Row className="text-start" data-aos="fade-up">
            <p>
            Space Giant Rocket Rescue is a virtual reality, physics-based puzzle game and is the first installment of the Space Giant extended reality games series.
            </p>
            <p>
            Space Giants are omnipotent beings that spend their eternal lives exploring space and time. And you are one of them! Stroxan, an alien on a mission, is stranded in space. As a Space Giant, you have the tools to help him and save his planet.
            </p>
            <p>
            Solve physics-based puzzles, explore space and different planets, collect new tools, discover hidden enemies, and help Stroxan complete his mission.
            </p>
            <p>Space Giant Rocket Rescue is the first virtual reality game released by indie developer Steven Clark. He brings his love of VR and puzzle games to life in this new gravity-defying space fantasy game.</p>
          </Row>
          <Row data-aos="fade-up text-start">
            <h3 className='text-start'>Features</h3>
          </Row>
          <Row className="text-start" data-aos="fade-up">
            <Col lg={8}>
              <Table>
                <tbody>
                <tr><td>Exploration, puzzles, and an unfolding story of intrigue and surprises.</td></tr>
                <tr><td>Help Stroxan, an alien in need, navigate his rocket and collect proto-crystals using unique tools.</td></tr>
                <tr><td>Simulation rockets fly through the level. You place your tools near the rocket's path to apply different forces and help navigate the ship.</td></tr>
                <tr><td>Visit planets with unique gravitational forces and challenges.</td></tr>
                <tr><td>Pick up side quests, you must find new tools to help you complete future levels.</td></tr>
                <tr><td>Upgrade weapons to fight enemies that threaten Stroxan and your mission to help him.</td></tr>
                </tbody>
              </Table>
            </Col>
            
          </Row>

              </Container>
    )
}

export default Press
