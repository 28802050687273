
import { Button, Card, Col, Container, Form, Image, InputGroup, Row } from 'react-bootstrap';
import BetaTest from './images/BetaTest.png'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import {
    useNavigate
  } from "react-router-dom";
import { useEffect } from 'react';
const CustomForm = ({ status, message, onValidated }) => {
    let email, fname, lname;
    const submit = () =>
      email &&
      fname &&
      lname &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
        FNAME: fname.value,
        LNAME: lname.value
      });
  
    return (
      <Card className="p-3 m-3 p-md-5 m-md-5">
        <Card.Header>Download the Beta Version</Card.Header>
        <Card.Body>

        {
            status ? (
            {
                sending: <div className="pt-md-3 pb-md-3" >sending...</div>,
                error: <div className="pt-md-3 pb-md-3" dangerouslySetInnerHTML={{ __html: message }} />,
                success: <><div className="pt-md-3 pb-md-3">Thank you, please click the Download button to receive the APK.</div>
                          <Button className="mb-md-3" href='https://drive.google.com/file/d/12UmYBhLETADR_LwjABZpO7-k-nZgkoKw/view?usp=share_link' target='_blank'>Download</Button>
                          <div className=" pb-md-3">If you need help installing, please head over to the <a href="./FAQ">FAQ</a> and read about installing using the SideQuest applicaiton.</div></>
            }[status]) : (
<>
                    <InputGroup>
                        <Form.Control
                            ref={node => (fname = node)}
                            type="text"
                            placeholder="Your first name" />
                        </InputGroup>
                        <br />
                        <InputGroup>
                        <Form.Control
                            ref={node => (lname = node)}
                            type="text"
                            placeholder="Your last name" />
                        </InputGroup>
                        <br />
                        <InputGroup>
                        <Form.Control
                            ref={node => (email = node)}
                            type="email"
                            placeholder="Your email" />
                        </InputGroup>
                        <br />
                    </>
            )
        }

  
        
        </Card.Body>
        {
            status ? '' : (
<Card.Footer>
          <Button variant="primary" onClick={submit}>
            Submit
          </Button>
        </Card.Footer>
            )
        }
        
        
        
      </Card>
    );
  };

  const holder = <MailchimpSubscribe
  url="https://spacegiantgame.us13.list-manage.com/subscribe/post?u=3bfc78ca4af334dc6fbc392a7&amp;id=9bd3237f39&amp;f_id=005ee2e2f0"
  render={({ subscribe, status, message }) => (
    <CustomForm
      status={status}
      message={message}
      onValidated={formData => subscribe(formData)}
    />
  )}
/>

const Beta = () => {

    return(
        <Container className="App-Page">

          <Row id="beta"  data-aos="fade-up">
          <Col className='justify-content-center'><h1>Become a Beta Tester</h1></Col>
          </Row>
          <Row data-aos="fade-up">
            <Col xs lg={6} >
            <Card className="p-3 m-3 p-md-5 m-md-5">
        <Card.Header>Download the Beta Version</Card.Header>
        <Card.Body>
            <div className="pt-md-3 pb-md-3">Please click the Download button to receive the APK.</div>
                          <Button className="mb-md-3" href='https://drive.google.com/file/d/12UmYBhLETADR_LwjABZpO7-k-nZgkoKw/view?usp=share_link' target='_blank'>Download</Button>
                          <div className=" pb-md-3">If you need help installing, please head over to the <a href="./FAQ">FAQ</a> and read about installing using the SideQuest applicaiton.</div>
                          </Card.Body>
                          </Card>
            </Col>
            <Col xs lg={6} >
              <Image fluid src={BetaTest}/>
            </Col>
          
          </Row>
          </Container>
    )
}

export default Beta
