import { useEffect, useState } from 'react';
import { Container, Stack, Image, Row, Col } from 'react-bootstrap';
import Img1 from "./images/img1.jpg"
import Img2 from "./images/img2.jpg"
import Img3 from "./images/img3.jpg"
import Img4 from "./images/img4.jpg"
import Img5 from "./images/img5.jpg"
import Thb1 from "./images/thb1.jpg"
import Thb2 from "./images/thb2.jpg"
import Thb3 from "./images/thb3.jpg"
import Thb4 from "./images/thb4.jpg"
import Thb5 from "./images/thb5.jpg"
import ThbV from "./images/thbV.jpg"

const Media = () => {

    const video = 'video'
    const pic1 = 'pic1'
    const pic2 = 'pic2'
    const pic3 = 'pic3'
    const pic4 = 'pic4'
    const pic5 = 'pic5'
    
    const defaultMedia = (
        <div className='d-flex justify-content-center ratio ratio-16x9 video'>
        <iframe key="trailer-video"  
            src="https://www.youtube.com/embed/-yzrfgAhDDs"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
        </iframe>
        </div>
    )

    const [selectedMedia, setSelectedMedia] = useState(video)
    const [media, setMedia] = useState(defaultMedia)

    useEffect(() => {
        switch(selectedMedia) {
            case video: 
                setMedia(defaultMedia)
                break;
            case pic1:
                setMedia(<Image key="img1-media" className="fadeIn" fluid src={Img1}/>)
                break;
            case pic2:
                setMedia(<Image key="img2-media" className="fadeIn" fluid src={Img2}/>)
                break;
            case pic3:
                setMedia(<Image key="img3-media" className="fadeIn" fluid src={Img3}/>)
                break;
            case pic4:
                setMedia(<Image key="img4-media" className="fadeIn" fluid src={Img4}/>)
                break;
            case pic5:
                setMedia(<Image key="img5-media" className="fadeIn" fluid src={Img5}/>)
                break;
        }
    }, [selectedMedia])

    return (
        <Stack direction='vertical' gap={3}>
            <Row><Col className='d-flex justify-content-center'>{media}</Col></Row>
            <Row>
                <Col><Image thumbnail src={ThbV}
                    onClick={evt => {
                        evt.preventDefault();
                        setSelectedMedia(video)
                    }}
                /></Col>
                <Col><Image thumbnail src={Thb1}
                    onClick={evt => {
                        evt.preventDefault();
                        setSelectedMedia(pic1)
                    }}
                /></Col>
                <Col><Image thumbnail src={Thb2}
                    onClick={evt => {
                        evt.preventDefault();
                        setSelectedMedia(pic2)
                    }}
                /></Col>
                <Col><Image thumbnail src={Thb3}
                    onClick={evt => {
                        evt.preventDefault();
                        setSelectedMedia(pic3)
                    }}
                /></Col>
                <Col><Image thumbnail src={Thb4}
                    onClick={evt => {
                        evt.preventDefault();
                        setSelectedMedia(pic4)
                    }}
                /></Col>
                <Col><Image thumbnail src={Thb5}
                    onClick={evt => {
                        evt.preventDefault();
                        setSelectedMedia(pic5)
                    }}
                /></Col>
            </Row>
        </Stack>
        
    )
}

export default Media