import { Card, Col, Container, Image, Row} from 'react-bootstrap';
import DownArrow from './images/expand_more_white_24dp.svg';
import Media from "./Media"
import Stroxan from './images/stroxan.png'
import ProtoCrystal from './images/protocrystal3.png'
import FireEx from './images/FirExtinguisher.png'
import Deflector from './images/DeflectorScreenShot.png'
import TractorBeam from './images/TractorBeamScreenShot.png'
import SpeedUp from './images/SpeedUpScreenShot.png'
import SlowDown from './images/SlowDownScreenShot.png'
import Misc from './Misc';
import FinalDeck from './FinalDeck';

const Home = () => {
    return(
        <>
        <header className="App-header">
          <img className="App-DownArrow bounce-7" src={DownArrow}/>
        </header>
        <Container>
          <Row className="justify-content-md-center mt-5 mb-5" data-aos="fade-up">
            <Col xs lg="8">
              <p className='App-Callout' id="trailer"><i>As a Space Giant, you transcend time and space. Stroxan, an alien on a mission, needs your help.</i></p>
            </Col>
          </Row>
          <Row data-aos="fade-up">
          <Media/>
          
          </Row>
          <Row className="justify-content-md-center mt-5 mb-5" data-aos="fade-up">
            <Col xs lg="8">
              <p className='App-Callout'><i>Space Giant Rocket Rescue is a physics-based VR puzzle game for everyone. You'll explore the expanse of space, visit unique planets, overcome challenges and solve puzzles along the way.</i></p>
            </Col>
          </Row>

          </Container>
          <Container fluid className='App-container-light'>
              <Container>
              <Row data-aos="fade-up">
            <Col className='justify-content-center'><h1>Story</h1></Col>
          </Row>
          <Row data-aos="fade-up">
            <Col xs lg="4" className="align-items-center">
              <Image src={Stroxan} />
            </Col>
            <Col xs lg="8" className="text-start d-flex flex-column justify-content-center">
              <p>Space Giant Rocket Rescue is the first in a series of extended reality games exploring the extraordinary powers of Space Giants.</p>
              <p>In this first installment, Stroxan, is on a mission to restore power to his planet and save his people. His navigation system fails, and he is stranded. Floating in space, his time is running out, and he needs help.</p>
              <p>As a Space Giant, you can save him and save his planet. But why did his rocket fail? What isn't he saying?</p>
            </Col>
          </Row>
          <Row data-aos="fade-up">
            <Col xs lg="8" className="text-start d-flex flex-column justify-content-center">
              <p>Stoxan’s planet runs on proto-crystals, but they have run out. He was chosen to search the universe and gather these powerful crystals.</p>
              <p>But why did his rocket fail? What isn’t he telling you?</p>
              <p>You have to help Stroxan complete his mission, but be careful that you do not become stranded as well.</p>
            </Col>
            <Col xs lg="4">
              <Image src={ProtoCrystal}/>
            </Col>
            
          </Row>
              </Container>
          </Container>
          
          <Container>
          <Row data-aos="fade-up">
            <Col className='justify-content-center'><h1>Museum of Tools</h1></Col>
          </Row>

          <Row className="justify-content-around mb-lg-4"  >
            <Col xs lg="6">
              <Card className=' h-100' data-aos="fade-up">
                <Row gap={0}>
                  <Col xs lg="4"><Card.Img variant="top" src={Deflector}/></Col>
                  <Col xs lg="8">
                <Card.Body>
                  <Card.Title>Deflector</Card.Title>
                  <Card.Text>The deflector is a very powerful tool, indeed. Crafted in the finest of Malorian steel and the latest in force field technology. The deflector pushes rockets away, sharply changing its direction.</Card.Text>
                </Card.Body>
                </Col>
                </Row>
              </Card>
            </Col>
            <Col  xs lg="6">
            <Card className=' h-100' data-aos="fade-up">
                <Row gap={0}>
                  <Col xs lg="4"><Card.Img variant="top" src={TractorBeam}/></Col>
                  <Col xs lg="8">
                <Card.Body>
                  <Card.Title>Tractor Beam</Card.Title>
                  <Card.Text>The tractor beam is a gentle but powerful tool. The attenuated linear graviton beam designed by the Immortal Nellorians pulls nearby rockets closer. By pulling the rockets closer, their trajectory is altered slightly.</Card.Text>
                </Card.Body>
                </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-around mb-lg-4">
            <Col xs lg="6">
              <Card className='h-100' data-aos="fade-up">
                <Row gap={0}>
                  <Col xs lg="4"><Card.Img variant="top" src={SpeedUp}/></Col>
                  <Col xs lg="8">
                <Card.Body>
                  <Card.Title>Speed Up Ring</Card.Title>
                  <Card.Text>Manipulating the folds of space-time, in a process only known to Space Giants, the Speed Up ring gives a power boost to any rocket that can fit through it. But be careful not to crash.</Card.Text>
                </Card.Body>
                </Col>
                </Row>
              </Card>
            </Col>
            <Col  xs lg="6">
            <Card  className=' h-100' data-aos="fade-up">
                <Row gap={0}>
                  <Col xs lg="4"><Card.Img variant="top" src={SlowDown}/></Col>
                  <Col xs lg="8">
                <Card.Body>
                  <Card.Title>Slow Down Ring</Card.Title>
                  <Card.Text>Once thought to be lost to the ages, dampening technology was recently rediscovered in the writings of the extinct Tre'ala. The dampening field generated by the slow down ring makes a rocket decrease its velocity by a nominal amount.</Card.Text>
                </Card.Body>
                </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="gx-5 mb-5  justify-content-around">
            <Col xs lg="6">
              <Card  className=' h-100' data-aos="fade-up">
                <Row gap={0}>
                  <Col xs lg="4"><Card.Img variant="top" src={FireEx}/></Col>
                  <Col xs lg="8">
                <Card.Body>
                  <Card.Title>Fire Extinguisher</Card.Title>
                  <Card.Text>A fire extinguisher? Why would a Space Giant need one of these?</Card.Text>
                </Card.Body>
                </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          </Container>
          <Container fluid className='App-container-light'>
            <Row data-aos="fade-up">
              <Col className='justify-content-center'><h1>Final Presentation</h1></Col>
            </Row>
            <Row><h3>Target Audiance: General Gaming Community</h3></Row>
            <Row><Col className='d-flex justify-content-center'>
              
            <div className='d-flex justify-content-center ratio ratio-16x9 video'>
        <iframe key="final-video"  
            src="https://www.youtube.com/embed/pi4KcY2pJSI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
        </iframe>
        </div>
            </Col></Row>


          </Container>
          <FinalDeck/>
          <Container>

          </Container>
          <Container fluid className='App-container-light'><Misc/></Container>
          
          </>
    )
}

export default Home
