import logo from './images/Logo.svg';
import { useState, useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { Button, Card, Col, Container, Form, Image, InputGroup, ListGroup, ListGroupItem, Navbar, Offcanvas, Row, Table } from 'react-bootstrap';
import Home from './Home';
import Beta from './Beta';
import Press from './Press';
import FAQ from './FAQ';
import AppNav from './AppNav';
import Contact from './Contact';

function App({props}) {

  
  return (
    <>
    <Router>
      <div className="App">
      <AppNav/>
      <Routes>
        <Route path="/Beta" element={<Beta />} caseSensitive={false}></Route>
        <Route path="/Press" element={<Press />} caseSensitive={false}></Route>
        <Route path="/FAQ" element={<FAQ />} caseSensitive={false}></Route>
        <Route path="/Contact" element={<Contact />} caseSensitive={false}></Route>
        <Route path="/" element={<Home/>} ></Route>
        <Route path="*" element={<Home/>}></Route>
      </Routes>
      </div>
      
    </Router>
    <footer className="App-Footer text-start py-3 mt-auto">&copy; {new Date().getFullYear()} Steven Clark</footer>
    </>
  )
  
}

export default App;
